import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Social from '../Social/Social';
import Popup from '../Popup/Popup';
import MobileMenu from '../MobileMenu/MobileMenu';
import { CurrentCity } from '../Cities/CurrentCity';
import Account from '../Account/Account';
import AppointmentButton from '../HeaderBtn/HeaderBtn';
import {
	getBottomMenu,
	getCities,
	getIsAuthenticated,
	getIsFirstStart,
	getPage,
	getSelectedCity,
	getTopMenu,
} from '@/store/selectors';

import styles from './Header.module.scss';
import { getBrowserGeolocation } from '@/utils/getBrowserGeolocation';
import { getGpsDistance } from '@/utils/getGpsDistance';
import { setSelectedCityIdAction, signOutAction } from '@/store/actions';

export const Header = () => {
	const isAuthenticated = useSelector(getIsAuthenticated);
	const isFirstStart = useSelector(getIsFirstStart);
	const topMenu = useSelector(getTopMenu);
	const bottomMenu = useSelector(getBottomMenu);
	const selectedCity = useSelector(getSelectedCity);
	const cities = useSelector(getCities);
	const dispatch = useDispatch();

	const [isCitiesPickerActive, setCitiesPickerActive] = React.useState(false);
	const [isUserPopupActive, setUserPopupActive] = React.useState(false);
	const [isBurgerMenuActive, setBurgerMenuActive] = React.useState(false);
	const history = useHistory();
	const page = useSelector(getPage);

	const socialVk = selectedCity?.socials.vk;
	const socialTg = selectedCity?.socials.tg;

	const goToAppointment = React.useCallback(() => {
		history.push('#appointment');
	}, [history]);

	const hideProfilePreview = React.useCallback(() => {
		setUserPopupActive(false);
	}, []);

	const goToProfile = React.useCallback(() => {
		if (isAuthenticated) {
			hideProfilePreview();
			history.push('#profile');
		}
	}, [history, isAuthenticated, hideProfilePreview]);

	const handleSignOutClick = React.useCallback(() => {
		hideProfilePreview();
		history.push('#');
		dispatch(signOutAction());
	}, [history, dispatch, hideProfilePreview]);

	const handleAccoutClick = React.useCallback(() => {
		if (isAuthenticated) {
			setUserPopupActive(true);
		} else {
			history.push('#signin');
		}
	}, [history, isAuthenticated]);

	useEffect(() => {
		if (isFirstStart) {
			getBrowserGeolocation()
				.then((result) => {
					if (result) {
						let closest = cities[0];
						let minDistance = Infinity;
						cities.forEach((city) => {
							const distance = getGpsDistance(city.coordX, city.coordY, result[0], result[1]);
							if (distance < minDistance) {
								closest = city;
								minDistance = distance;
							}
						});
						dispatch(setSelectedCityIdAction(closest.city.id));
					} else {
						setCitiesPickerActive(true);
					}
				})
				.catch(() => {
					setCitiesPickerActive(true);
				});
		}
	}, [isFirstStart, cities, dispatch]);

	return (
		<div className={styles.container}>
			<AppointmentButton className={styles.appointmentButtonMobile} onClick={goToAppointment}/>
			<div className={styles.main}>
				{page?.template !== 'salon-page' && (
					<div className={styles.socialIcons}>
						<div className={styles.socialIcon}>
							<Social
								link={socialVk}
								type={'vk'}
								place={'header'}
							/>
						</div>
						<div className={styles.socialIcon}>
							<Social
								link={socialTg}
								type={'telegram'}
								place={'header'}
							/>
						</div>
					</div>
				)}
				<div className={styles.currentCity}>
					<CurrentCity
						active={isCitiesPickerActive}
						onClick={() => setCitiesPickerActive(true)}
						onClosePicker={() => setCitiesPickerActive(false)}
					/>
				</div>
				<div className={styles.account}>
					<Account
						previewActive={isUserPopupActive}
						onClickSignOut={handleSignOutClick}
						onClickAppointment={goToAppointment}
						onClickProfile={goToProfile}
						onClick={handleAccoutClick}
						onClosePreview={hideProfilePreview}
					/>
				</div>
				<AppointmentButton className={styles.appointmentButton} onClick={goToAppointment}/>
				<div className={styles.mobileMenuContainer}>
					<i className={styles.mobileMenuIcon} onClick={(e) => {
						e.stopPropagation();
						setBurgerMenuActive(true);
					}}/>
					<Popup
						className={styles.burgerMobile}
						place={'burger'}
						active={isBurgerMenuActive}
						onClose={() => setBurgerMenuActive(false)}
					>
						<MobileMenu
							topMenu={topMenu}
							bottomMenu={bottomMenu}
							onClose={() => setBurgerMenuActive(false)}
						/>
					</Popup>
				</div>
			</div>
		</div>
	);
};
